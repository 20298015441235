// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dogadjaji-js": () => import("./../../../src/pages/dogadjaji.js" /* webpackChunkName: "component---src-pages-dogadjaji-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-korisni-linkovi-js": () => import("./../../../src/pages/korisni-linkovi.js" /* webpackChunkName: "component---src-pages-korisni-linkovi-js" */),
  "component---src-pages-o-nama-js": () => import("./../../../src/pages/o-nama.js" /* webpackChunkName: "component---src-pages-o-nama-js" */),
  "component---src-pages-proizvodi-i-usluge-laboratorijska-oprema-i-potrosni-materijal-servisno-aplikativna-podrska-js": () => import("./../../../src/pages/proizvodi-i-usluge/laboratorijska-oprema-i-potrosni-materijal/servisno-aplikativna-podrska.js" /* webpackChunkName: "component---src-pages-proizvodi-i-usluge-laboratorijska-oprema-i-potrosni-materijal-servisno-aplikativna-podrska-js" */),
  "component---src-pages-servisno-aplikativna-podrska-primljen-zahtev-js": () => import("./../../../src/pages/servisno-aplikativna-podrska-primljen-zahtev.js" /* webpackChunkName: "component---src-pages-servisno-aplikativna-podrska-primljen-zahtev-js" */),
  "component---src-pages-vesti-js": () => import("./../../../src/pages/vesti.js" /* webpackChunkName: "component---src-pages-vesti-js" */),
  "component---src-templates-category-page-template-js": () => import("./../../../src/templates/categoryPageTemplate.js" /* webpackChunkName: "component---src-templates-category-page-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-products-list-page-template-js": () => import("./../../../src/templates/productsListPageTemplate.js" /* webpackChunkName: "component---src-templates-products-list-page-template-js" */)
}

